<template>
  <section class="panel" :class="`panel--${isPanelOpen}`">
    <div class="panel-top">
      <div class="panel-top-wrapper">
        <svg
          class="panel-icon"
          width="47"
          height="47"
          viewBox="0 0 47 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.5002 46.4166C36.1043 46.4166 46.4168 36.1041 46.4168 23.4999C46.4168 10.8958 36.1043 0.583252 23.5002 0.583252C10.896 0.583252 0.583496 10.8958 0.583496 23.4999C0.583496 36.1041 10.896 46.4166 23.5002 46.4166ZM21.2085 12.0416H25.7918V16.6249H21.2085V12.0416ZM28.0835 34.9582H18.9168V30.3749H21.2085V25.7916H18.9168V21.2083H25.7918V30.3749H28.0835V34.9582Z"
            fill="#67247C"
          />
        </svg>
        <h2 class="panel-heading">Information</h2>
      </div>
      <button class="button" type="button" @click="togglePanelOpen">
        <v-icon v-if="panelArrow">expand_more</v-icon>
        <v-icon v-if="!panelArrow">expand_less</v-icon>
      </button>
    </div>

    <div class="panel-bottom">
      <transition name="fade">
        <div v-if="!panelArrow" class="panel-bottom-label">
          The details entered in this form will appear on an information panel
          on the map, each information panel will consist of four areas:
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!panelArrow" class="panel-bottom-content fade">
          <img
            class="panel-desktop"
            src="../../../assets/map-directory/desktop.png"
            alt=""
          />
          <img
            class="panel-arrow"
            src="../../../assets/map-directory/arrow.png"
            alt=""
          />
          <img
            class="panel-frame"
            src="../../../assets/map-directory/frame.png"
            alt=""
          />

          <div class="panel-labels-container">
            <p>Address</p>
            <p>External Links</p>
            <p>Contact</p>
            <p>General Information</p>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapInfoWindowToggle",

  data: () => ({
    isPanelOpen: "closed",
    panelArrow: true,
  }),

  prop: {
    stepperCount: {
      type: Number,
      required: false,
    },
  },

  methods: {
    togglePanelOpen() {
      if (this.isPanelOpen === "closed") {
        this.isPanelOpen = "open";
        this.panelArrow = false;
      } else if (this.isPanelOpen === "open") {
        this.isPanelOpen = "closed";
        this.panelArrow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  background-color: #e3f0f9;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  transition: 400ms;

  &--open {
    height: 150px;

    @media (min-width: 640px) {
      height: 250px;
    }
  }

  &-icon {
    height: 24px;
    width: 24px;
  }

  &-top-wrapper {
    display: flex;
    align-items: center;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
  }

  &-heading {
    font-size: 16px;
    font-weight: 500;
    color: #516173;
    margin: 0 5px;
  }

  &-bottom {
    margin: 0 16px;
  }

  &-bottom-content {
    display: none;
    width: 100%;
    margin: 16px 0;

    @media (min-width: 640px) {
      display: flex;
    }
  }

  &-bottom-label {
    font-size: 14px;
    font-weight: 300;
    color: #516173;
    transition: ease-in-out;
  }

  &-desktop {
    width: 180px;
    height: 120px;
  }

  &-frame {
    width: 70px;
    height: 120px;
  }

  &-arrow {
    position: relative;
    top: 50px;
    width: 50px;
    height: 8px;
    margin: 0 16px;
  }

  &-labels-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0.6;
    margin: 0 16px;
    color: #516173;
  }
}

.button {
  padding: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>