<template>
    <form class="main-body">
        <h2 class="subheading">{{ heading }}</h2>

        <map-info-window-toggle :stepperCount="stepperCount" />

        <div class="subheading-wrapper">
            <h2>LOCATIONS</h2>
        </div>

        <v-stepper v-model="stepperCount">
            <v-stepper-header class="stepper-header">
                <v-stepper-step
                    color="accent"
                    :complete="stepperCount > 1"
                    step="1"
                >Location</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    color="accent"
                    :complete="stepperCount > 2"
                    step="2"
                >Name & Address</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    color="accent"
                    :complete="stepperCount > 3"
                    step="3"
                >External Links</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    color="accent"
                    :complete="stepperCount > 4"
                    step="4"
                >Contact</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    color="accent"
                    :complete="stepperCount > 5"
                    step="5"
                >General Information</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <div class="stepper-wrapper">
                        <v-card
                            class="mb-5"
                            flat
                            color="white lighten-1"
                            height="550px"
                        >
                            <label class="label">Location</label>
                            <p class="stepper-label--mini">
                                We need to confirm the location so a pin can be populated on the
                                map.
                            </p>

                            <p class="stepper-label--mini">
                                Does the location have a fixed address?
                            </p>

                            <div class="checkbox-wrapper">
                                <div class="checkbox-auto">
                                    <p class="checkbox-label">Yes</p>
                                    <v-checkbox v-model="locationFixed">
                                        <template v-slot:label> </template>
                                    </v-checkbox>
                                </div>

                                <div class="checkbox-auto">
                                    <p class="checkbox-label">No</p>
                                    <v-checkbox v-model="locationAuto">
                                        <template v-slot:label> </template>
                                    </v-checkbox>
                                </div>
                            </div>

                            <map-picker
                                class="map-picker"
                                :latVal="53.39381493981905"
                                :lngVal="-2.598885259558612"
                                :displayName="'Test'"
                                @latUpdate="updateLat($event)"
                                @lngUpdate="updateLng($event)"
                            />

                            <div
                                v-if="isStepperAddressOpen"
                                class="fixed-address-container"
                            >
                                <div class="fixed-address-latitude">
                                    <label
                                        class="fixed-address__label"
                                        for=""
                                    >Latitude</label>
                                    <v-text-field
                                        class="stepper__list-item-input"
                                        v-model="updatedData.coords.lat"
                                        solo
                                        flat
                                        placeholder="53.441111"
                                        height="20"
                                    ></v-text-field>
                                </div>
                                <div class="fixed-address-longtitude">
                                    <label
                                        class="fixed-address__label"
                                        for=""
                                    >Longitude</label>
                                    <v-text-field
                                        class="stepper__list-item-input"
                                        v-model="updatedData.coords.lng"
                                        solo
                                        flat
                                        placeholder="-2.23678"
                                        height="20"
                                    ></v-text-field>
                                </div>
                            </div>
                        </v-card>
                    </div>
                    <div class="buttons-wrapper">
                        <v-btn
                            class="button-cancel"
                            color="lightgrey"
                            @click="cancelLocation()"
                        >
                            Cancel
                        </v-btn>

                        <div class="buttons-continue">
                            <!-- <v-btn class="button-skip" flat @click="stepperCount = 2">
                Skip
              </v-btn> -->

                            <v-btn
                                color="accent"
                                @click="stepperCount = 2"
                            > Next </v-btn>
                        </div>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <div class="stepper-wrapper">
                        <v-card
                            class="mb-5"
                            flat
                            color="white lighten-1"
                            height="550px"
                        >
                            <label class="label">Name and Address</label>
                            <p class="stepper-label--mini">
                                Provide the name of you location and the address that will
                                appear on the details panel.
                            </p>
                            <label class="label">Name</label>

                            <p class="stepper-label--mini">
                                Confirm the name of your location e.g Halifax Supporters Club.
                            </p>

                            <v-text-field
                                v-model="updatedData.locationTitle"
                                :placeholder="'Halifax Supporters Club'"
                                flat
                                solo
                            >
                            </v-text-field>

                            <label class="label">Address</label>

                            <p class="stepper-label--mini">
                                Please provide the address of your location.
                            </p>

                            <v-text-field
                                v-model="updatedData.addressData.addressNumber"
                                :placeholder="'Apt. House name or number'"
                                solo
                                flat
                            ></v-text-field>
                            <v-text-field
                                v-model="updatedData.addressData.addressLineOne"
                                :placeholder="'Address line 1'"
                                solo
                                flat
                            ></v-text-field>
                            <v-text-field
                                v-model="updatedData.addressData.addressLineTwo"
                                :placeholder="'Address line 2'"
                                solo
                                flat
                            ></v-text-field>
                            <div class="postcode-wrapper">
                                <v-text-field
                                    v-model="updatedData.addressData.city"
                                    class="text-field-small"
                                    :placeholder="'Town or City'"
                                    solo
                                    flat
                                ></v-text-field>
                                <v-text-field
                                    v-model="updatedData.addressData.postcode"
                                    :placeholder="'Postcode'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>
                        </v-card>
                    </div>

                    <div class="buttons-wrapper">
                        <v-btn
                            class="button-cancel"
                            color="lightgrey"
                            @click="stepperCount = 1"
                        >
                            Back
                        </v-btn>

                        <div class="buttons-continue">
                            <!-- <v-btn class="button-skip" flat @click="stepperCount = 3">
                Skip
              </v-btn> -->

                            <v-btn
                                color="accent"
                                @click="stepperCount = 3"
                            > Next </v-btn>
                        </div>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <div class="stepper-wrapper">
                        <v-card
                            class="mb-5"
                            flat
                            color="white lighten-1"
                            height="550px"
                        >
                            <label class="label">External Links</label>
                            <p class="stepper-label--mini">
                                Add any websites or social media links connected to the
                                location.
                            </p>

                            <label class="label">Social Media</label>

                            <p class="stepper-label--mini">
                                Enter any Social Media links for the location using the format
                                shown.
                            </p>

                            <div class="stepper-social-wrapper">
                                <div class="stepper-social-info-wrapper">
                                    <p class="stepper-social-wrapper__label">Website</p>
                                </div>

                                <v-text-field
                                    v-model="updatedData.socialsData.website.url"
                                    :placeholder="'https://www.websiteurl.com'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>

                            <div class="stepper-social-wrapper">
                                <div class="stepper-social-info-wrapper">
                                    <p class="stepper-social-wrapper__label">X</p>

                                    <svg
                                        class="stepper-social-wrapper__icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <g clip-path="url(#a)">
                                            <path
                                                fill="currentColor"
                                                d="M13.969 10.157 22.707 0h-2.07l-7.588 8.82L6.99 0H0l9.164 13.336L0 23.988h2.07l8.013-9.314 6.4 9.314h6.989l-9.504-13.83h.001Zm-2.836 3.297-.929-1.328L2.817 1.559h3.18l5.962 8.528.929 1.328 7.75 11.085h-3.181l-6.324-9.046Z"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="a">
                                                <path
                                                    fill="currentColor"
                                                    d="M0 0h23.472v24H0z"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                                <v-text-field
                                    v-model="updatedData.socialsData.twitter.url"
                                    :placeholder="'https://www.x.com/username'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>

                            <div class="stepper-social-wrapper">
                                <div class="stepper-social-info-wrapper">
                                    <p class="stepper-social-wrapper__label">Facebook</p>

                                    <svg
                                        class="stepper-social-wrapper__icon"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8.00001 1.33337C4.32001 1.33337 1.33334 4.32004 1.33334 8.00004C1.33334 11.2267 3.62668 13.9134 6.66668 14.5334V10H5.33334V8.00004H6.66668V6.33337C6.66668 5.04671 7.71334 4.00004 9.00001 4.00004H10.6667V6.00004H9.33334C8.96668 6.00004 8.66668 6.30004 8.66668 6.66671V8.00004H10.6667V10H8.66668V14.6334C12.0333 14.3 14.6667 11.46 14.6667 8.00004Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                                <v-text-field
                                    v-model="updatedData.socialsData.facebook.url"
                                    :placeholder="'https://www.facebook.com/username'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>

                            <div class="stepper-social-wrapper">
                                <div class="stepper-social-info-wrapper">
                                    <p class="stepper-social-wrapper__label">Instagram</p>

                                    <svg
                                        class="stepper-social-wrapper__icon"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.00001 5.83337C7.42537 5.83337 6.87427 6.06165 6.46795 6.46798C6.06162 6.87431 5.83334 7.42541 5.83334 8.00004C5.83334 8.57468 6.06162 9.12578 6.46795 9.53211C6.87427 9.93843 7.42537 10.1667 8.00001 10.1667C8.57465 10.1667 9.12575 9.93843 9.53207 9.53211C9.9384 9.12578 10.1667 8.57468 10.1667 8.00004C10.1667 7.42541 9.9384 6.87431 9.53207 6.46798C9.12575 6.06165 8.57465 5.83337 8.00001 5.83337Z"
                                            fill="black"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M4.51333 2.05471C6.83067 1.79784 9.16931 1.79784 11.4867 2.05471C12.7527 2.19604 13.7733 3.19271 13.922 4.46338C14.1968 6.81315 14.1968 9.18694 13.922 11.5367C13.7733 12.8074 12.7527 13.804 11.4873 13.946C9.16976 14.203 6.83089 14.203 4.51333 13.946C3.24733 13.804 2.22666 12.8074 2.07799 11.5374C1.80314 9.18739 1.80314 6.81337 2.07799 4.46338C2.22666 3.19271 3.24733 2.19604 4.51333 2.05471ZM11.3333 4.00004C11.1565 4.00004 10.9869 4.07028 10.8619 4.19531C10.7369 4.32033 10.6667 4.4899 10.6667 4.66671C10.6667 4.84352 10.7369 5.01309 10.8619 5.13811C10.9869 5.26314 11.1565 5.33338 11.3333 5.33338C11.5101 5.33338 11.6797 5.26314 11.8047 5.13811C11.9298 5.01309 12 4.84352 12 4.66671C12 4.4899 11.9298 4.32033 11.8047 4.19531C11.6797 4.07028 11.5101 4.00004 11.3333 4.00004ZM4.83333 8.00004C4.83333 7.16019 5.16696 6.35474 5.76082 5.76087C6.35469 5.16701 7.16014 4.83338 7.99999 4.83338C8.83984 4.83338 9.6453 5.16701 10.2392 5.76087C10.833 6.35474 11.1667 7.16019 11.1667 8.00004C11.1667 8.8399 10.833 9.64535 10.2392 10.2392C9.6453 10.8331 8.83984 11.1667 7.99999 11.1667C7.16014 11.1667 6.35469 10.8331 5.76082 10.2392C5.16696 9.64535 4.83333 8.8399 4.83333 8.00004Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                                <v-text-field
                                    v-model="updatedData.socialsData.instagram.url"
                                    :placeholder="'https://www.instagram.com/username'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>

                            <div class="stepper-social-wrapper">
                                <div class="stepper-social-info-wrapper">
                                    <p class="stepper-social-wrapper__label">TikTok</p>
                                    <svg
                                        class="stepper-social-wrapper__icon"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.0667 3.88C10.611 3.35975 10.3598 2.69162 10.36 2H8.30001V10.2667C8.28412 10.714 8.09526 11.1377 7.77319 11.4486C7.45112 11.7595 7.02098 11.9333 6.57335 11.9333C5.62668 11.9333 4.84001 11.16 4.84001 10.2C4.84001 9.05333 5.94668 8.19333 7.08668 8.54667V6.44C4.78668 6.13333 2.77335 7.92 2.77335 10.2C2.77335 12.42 4.61335 14 6.56668 14C8.66001 14 10.36 12.3 10.36 10.2V6.00667C11.1953 6.60657 12.1983 6.92843 13.2267 6.92667V4.86667C13.2267 4.86667 11.9733 4.92667 11.0667 3.88Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                                <v-text-field
                                    v-model="updatedData.socialsData.tiktok.url"
                                    :placeholder="'https://www.tiktok.com/@username'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>

                            <div class="stepper-social-wrapper">
                                <div class="stepper-social-info-wrapper">
                                    <p class="stepper-social-wrapper__label">Youtube</p>

                                    <svg
                                        class="stepper-social-wrapper__icon"
                                        width="16"
                                        height="12"
                                        viewBox="0 0 16 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.051 -0.000976562H8.14C8.962 0.00202344 13.127 0.0320236 14.25 0.334024C14.5895 0.426192 14.8989 0.605846 15.1472 0.855032C15.3955 1.10422 15.574 1.41421 15.665 1.75402C15.766 2.13402 15.837 2.63702 15.885 3.15602L15.895 3.26002L15.917 3.52002L15.925 3.62402C15.99 4.53802 15.998 5.39402 15.999 5.58102V5.65602C15.998 5.85002 15.989 6.76402 15.917 7.71602L15.909 7.82102L15.9 7.92502C15.85 8.49702 15.776 9.06502 15.665 9.48302C15.5743 9.82298 15.3958 10.1331 15.1475 10.3823C14.8991 10.6316 14.5896 10.8111 14.25 10.903C13.09 11.215 8.681 11.237 8.07 11.238H7.928C7.619 11.238 6.341 11.232 5.001 11.186L4.831 11.18L4.744 11.176L4.573 11.169L4.402 11.162C3.292 11.113 2.235 11.034 1.748 10.902C1.40849 10.8102 1.09907 10.6308 0.850724 10.3818C0.602384 10.1327 0.423847 9.82279 0.333 9.48302C0.222 9.06602 0.148 8.49702 0.098 7.92502L0.09 7.82002L0.082 7.71602C0.0326476 7.03842 0.00529987 6.3594 0 5.68002L0 5.55702C0.002 5.34202 0.01 4.59902 0.064 3.77902L0.071 3.67602L0.074 3.62402L0.082 3.52002L0.104 3.26002L0.114 3.15602C0.162 2.63702 0.233 2.13302 0.334 1.75402C0.424694 1.41407 0.603162 1.10393 0.851513 0.854706C1.09986 0.605477 1.40937 0.425916 1.749 0.334024C2.236 0.204024 3.293 0.124023 4.403 0.0740234L4.573 0.0670234L4.745 0.0610236L4.831 0.0580236L5.002 0.0510236C5.95371 0.0203982 6.90581 0.0033963 7.858 2.34842e-05H8.051V-0.000976562ZM6.4 3.20902V8.02702L10.557 5.61902L6.4 3.20902Z"
                                            fill="black"
                                        />
                                    </svg>
                                </div>

                                <v-text-field
                                    v-model="updatedData.socialsData.youtube.url"
                                    :placeholder="'https://www.youtube.com/@username'"
                                    solo
                                    flat
                                ></v-text-field>
                            </div>
                        </v-card>
                    </div>

                    <div class="buttons-wrapper">
                        <v-btn
                            class="button-cancel"
                            color="lightgrey"
                            @click="stepperCount = 2"
                        >
                            Back
                        </v-btn>

                        <div class="buttons-continue">
                            <!-- <v-btn class="button-skip" flat @click="stepperCount = 4">
                Skip
              </v-btn> -->

                            <v-btn
                                color="accent"
                                @click="stepperCount = 4"
                            > Next </v-btn>
                        </div>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <div class="stepper-wrapper">
                        <v-card
                            class=""
                            flat
                            color="white lighten-1"
                            height="550px"
                        >
                            <label class="label">Contact</label>
                            <p class="stepper-label--mini">
                                Add contact details related to this location.
                            </p>
                            <label class="label">Main Contact</label>

                            <p class="stepper-label--mini">
                                Please enter the name or department below.
                            </p>

                            <v-text-field
                                v-model="updatedData.contact.name"
                                :placeholder="'Alan Smith'"
                                flat
                                solo
                            >
                            </v-text-field>

                            <label class="label">Email</label>

                            <v-text-field
                                v-model="updatedData.contact.email"
                                :placeholder="'alansmith@outlook.com'"
                                flat
                                solo
                            >
                            </v-text-field>
                        </v-card>
                    </div>

                    <div class="buttons-wrapper">
                        <v-btn
                            class="button-cancel"
                            color="lightgrey"
                            @click="stepperCount = 3"
                        >
                            Back
                        </v-btn>

                        <!-- <div class="buttons-continue">
              <v-btn class="button-skip" flat @click="stepperCount = 5">
                Skip
              </v-btn> -->

                        <v-btn
                            color="accent"
                            @click="stepperCount = 5"
                        > Next </v-btn>
                    </div>
                </v-stepper-content>

                <v-stepper-content step="5">
                    <div class="stepper-wrapper">
                        <v-card
                            flat
                            color="white lighten-1"
                            height="550px"
                        >
                            <label class="label">General Information</label>
                            <p class="stepper-label--mini">
                                This can be any information you would like those viewing the
                                location to know.
                            </p>

                            <v-textarea
                                v-model="updatedData.description"
                                solo
                                flat
                            ></v-textarea>
                        </v-card>
                    </div>

                    <div class="buttons-wrapper">
                        <div class="buttons-wrapper">
                            <v-btn
                                class="button-cancel"
                                color="lightgrey"
                                @click="stepperCount = 4"
                            >
                                Back
                            </v-btn>
                            <div class="buttons-continue">
                                <v-btn
                                    color="accent"
                                    @click="updateLocation()"
                                >
                                    {{ confirmButtonText ? "Continue" : "Update" }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </form>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import MapPicker from "@/components/Library/MapPicker";
import MapInfoWindowToggle from "@/components/MapDirectory/MapInfoWindow/MapInfoWindowToggle.vue";
import Editor from "@tinymce/tinymce-vue";
import PageSelector from "@/components/Library/PageSelector";

export default {
    name: "EditMapLocations",

    components: {
        ImageUploader,
        MapPicker,
        "tinymce-editor": Editor,
        PageSelector,
        MapInfoWindowToggle,
    },

    created() {
        this.fetchCategorySingle();

        // console.log("PARAM OUTPUT", this.$route.params.category_id);
        this.category_id = this.$route.params.category_id;
        this.mapLocationID = this.$uuid.v1();

        if (this.$route.params.location_id != "create") {
            this.fetchLocationSingle();
            this.heading = "Editing Location";
            this.isExistingLocation = true;
            this.confirmButtonText = false;
        } else {
            this.heading = "Creating New Location";
            this.isExistingLocation = false;
            this.confirmButtonText = true;
            this.isLoaded = true;
        }
    },

    watch: {
        locationAuto(newValue) {
            if (newValue) {
                (this.locationFixed = false), (this.updatedData.areaType = "auto");
                this.isStepperAddressOpen = false;
                this.updatedData.coords.lat = null;
                this.updatedData.coords.lng = null;
            }
        },
        locationFixed(newValue) {
            if (newValue) {
                this.locationAuto = false;
                this.updatedData.areaType = "fixed";
                this.isStepperAddressOpen = true;
            }
        },
    },

    data: () => ({
        api: process.env.VUE_APP_MAPDIRECTORYAPI,
        isExistingCategory: null,
        confirmButtonText: null,
        stepperCount: 0,
        heading: null,
        categoriesArray: [],
        selectedCategory: null,
        locationFixed: false,
        locationAuto: false,
        isStepperAddressOpen: false,
        updatedData: {
            locationTitle: null,
            areaType: null,
            coords: {
                lat: null,
                lng: null,
            },
            contact: {
                name: null,
                email: null,
            },
            socialsData: {
                facebook: {
                    title: "Facebook",
                    url: null,
                },
                instagram: {
                    title: "Instagram",
                    url: null,
                },
                tiktok: {
                    title: "Tik Tok",
                    url: null,
                },
                twitter: {
                    title: "Twitter",
                    url: null,
                },
                website: {
                    title: "Website",
                    url: null,
                },
                youtube: {
                    title: "Youtube",
                    url: null,
                },
            },
            description: "",
            addressData: {
                addressNumber: "",
                addressLineOne: "",
                addressLineTwo: "",
                city: "",
                postcode: "",
            },
        },
        categoryData: {
            colour: null,
            title: null,
            locations: null,
            id: null,
            type: null,
        },
    }),

    methods: {
        cancelLocation() {
            // Get current path minus 'create'
            const path = window.location.pathname;
            const parts = path.split("/");
            const lastPart = parts.pop();

            let url = "/";

            if (lastPart === "create") {
                url = parts.join("/");
            } else {
                url = path;
            }

            this.$router.push({ path: url });
        },

        updateLat(latitude) {
            this.updatedData.coords.lat = latitude;
        },

        updateLng(longitude) {
            this.updatedData.coords.lng = longitude;
        },

        updateLocation() {
            this.$store.commit("startLoading");

            // Create custom socials object to cater to api's schema

            const socials = {};
            for (const prop in this.updatedData.socialsData) {
                if (this.updatedData.socialsData[prop].url) {
                    socials[prop] = {
                        title: this.updatedData.socialsData[prop].title,
                        url: this.updatedData.socialsData[prop].url,
                    };
                }
            }

            const contact = {};
            for (const prop in this.updatedData.contact) {
                if (this.updatedData.contact[prop]) {
                    contact[prop] = this.updatedData.contact[prop];
                }
            }

            if (this.isExistingLocation) {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${this.api}/locations/${this.mapLocationSingleID}`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                attributes: {
                                    title: this.updatedData.locationTitle,
                                    address: this.updatedData.addressData,
                                    coords: {
                                        lat: Number(this.updatedData.coords.lat),
                                        lng: Number(this.updatedData.coords.lng),
                                    },
                                    contact,
                                    description: this.updatedData.description,
                                    socials,
                                },
                                type: "string",
                                id: this.mapLocationSingleID,
                            },
                        },
                    })
                        .then((response) => {
                            this.$router.push({ path: "/map-directory" });
                            window.alert("Successfully Saved");
                            this.$store.commit("completeLoading");
                            // this.updateCategoriesLocation(); // this function doesn't exist in the app 
                        })
                        .catch((e) => {
                            console.error("ERROR PATCH location data", e);
                            window.alert("Please define a map name and coordinates");
                            this.$store.commit("completeLoading");
                        });
                });
            } else {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "POST",
                        url: `${this.api}/locations`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                attributes: {
                                    mapLocationID: this.mapLocationID,
                                    title: this.updatedData.locationTitle,
                                    address: this.updatedData.addressData,
                                    coords: {
                                        lat: Number(this.updatedData.coords.lat),
                                        lng: Number(this.updatedData.coords.lng),
                                    },
                                    contact,
                                    description: this.updatedData.description,
                                    socials,
                                },
                                type: "string",
                            },
                        },
                    })
                        .then((response) => {
                            this.categoryData.locations.push(this.mapLocationID);
                            this.updateSingleCategory();
                            console.log("categoryData", this.categoryData);
                            this.$router.push({ path: "/map-directory" });
                            this.$store.commit("completeLoading");
                        })
                        .catch((e) => {
                            console.error("ERROR POST location data", e);
                            window.alert("Please define a map name and coordinates");
                            this.$store.commit("completeLoading");
                        });
                });
            }
        },

        fetchCategorySingle() {
            this.$store.commit("startLoading");
            this.category_id = this.$route.params.category_id;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/categories/${this.category_id}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        this.data = response.data.data;

                        this.categoryData.title = this.data.attributes.title;
                        this.categoryData.colour = this.data.attributes.colour;
                        this.categoryData.locations = this.data.attributes.locations;
                        this.categoryData.id = this.data.id;

                        console.log("Get single category", this.data);

                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem receiving category data", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        updateSingleCategory() {
            if (this.categoryData) {
                this.category_id = this.$route.params.category_id;
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${this.api}/categories/${this.category_id}`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                attributes: {
                                    title: this.categoryData.title,
                                    colour: this.categoryData.colour,
                                    locations: this.categoryData.locations,
                                },
                                id: this.categoryData.id,
                                type: "string",
                            },
                        },
                    })
                        .then((response) => {
                            window.alert("Successfully Saved");
                            this.$store.commit("completeLoading");
                        })
                        .catch((e) => {
                            console.error("ERROR PATCH category data", e);
                            this.$store.commit("completeLoading");
                        });
                });
            }
        },

        fetchLocationSingle() {
            this.$store.commit("startLoading");
            this.mapLocationSingleID = this.$route.params.location_id;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/locations/${this.mapLocationSingleID}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        this.data = response.data.data;
                        console.log("Get single location", response.data.data);

                        this.updatedData.locationTitle = this.data.attributes.title;
                        this.updatedData.addressData.addressLineOne =
                            this.data.attributes.address.addressLineOne;
                        this.updatedData.addressData.addressLineTwo =
                            this.data.attributes.address.addressLineTwo;
                        this.updatedData.addressData.addressNumber =
                            this.data.attributes.address.addressNumber;
                        this.updatedData.addressData.city =
                            this.data.attributes.address.city;
                        this.updatedData.addressData.postcode =
                            this.data.attributes.address.postcode;

                        if (this.data.attributes.socials && this.data.attributes.socials.website) {
                            this.updatedData.socialsData.website.url =
                                this.data.attributes.socials.website.url;
                        }
                        if (this.data.attributes.socials && this.data.attributes.socials.twitter) {
                            this.updatedData.socialsData.twitter.url =
                                this.data.attributes.socials.twitter.url;
                        }
                        if (this.data.attributes.socials && this.data.attributes.socials.facebook) {
                            this.updatedData.socialsData.facebook.url =
                                this.data.attributes.socials.facebook.url;
                        }
                        if (this.data.attributes.socials && this.data.attributes.socials.instagram) {
                            this.updatedData.socialsData.instagram.url =
                                this.data.attributes.socials.instagram.url;
                        }
                        if (this.data.attributes.socials && this.data.attributes.socials.tiktok) {
                            this.updatedData.socialsData.tiktok.url =
                                this.data.attributes.socials.tiktok.url;
                        }
                        if (this.data.attributes.socials && this.data.attributes.socials.youtube) {
                            this.updatedData.socialsData.youtube.url =
                                this.data.attributes.socials.youtube.url;
                        }

                        this.updatedData.contact.name = this.data.attributes.contact.name;
                        this.updatedData.contact.email = this.data.attributes.contact.email;
                        this.updatedData.description = this.data.attributes.description;

                        if (this.data.attributes.coords.lat) {
                            this.isStepperAddressOpen = true;
                            this.locationAuto = false;
                            this.updatedData.areaType = "fixed";
                            this.updatedData.coords.lat = this.data.attributes.coords.lat;
                            this.updatedData.coords.lng = this.data.attributes.coords.lng;
                            this.locationFixed = true;
                        } else {
                            this.locationAuto = true;
                            this.locationFixed = false;
                            this.updatedData.areaType = "auto";
                        }

                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem receiving location data", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },
    },
};
</script>

<style scoped lang='scss'>
.stepper {
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 90px;
        overflow: auto;
    }

    &-content-container {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }

    &-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 130px;
        height: 130px;
        margin: 44px;
        border-radius: 4px;
        background-color: #f9f9f9;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

        &--icon {
            position: relative;
            top: -10px;
        }
    }

    &-content-label {
        font-size: 14px;
        font-weight: 200;
        text-transform: uppercase;
        color: #516173;

        &--mini {
            color: #a8aab7;
            font-size: 12px;
            font-weight: 500;
            text-transform: capitalize;
        }

        &--heading {
            font-size: 16px;
            font-weight: 500;
            color: #24002f;
            text-transform: capitalize;
        }
    }

    &-label--mini {
        color: #516173;
    }

    &-label--inline {
        color: #24002f;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;
    }

    &-social-wrapper {
        display: flex;
        align-items: center;

        &__label {
            margin-right: 30px;
            position: relative;
            top: -5px;
        }

        &__icon {
            position: relative;
            // top: -13px;
            right: 15px;
            height: 13px;
            width: 13px;
        }
    }

    &-social-info-wrapper {
        display: flex;
        justify-content: space-between;
        width: 110px;
    }
}

.checkbox-wrapper {
    display: flex;
}

.checkbox-auto {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.checkbox-label {
    margin: 0 !important;
}

.half {
    width: 100%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.faded {
    opacity: 0.5;
}

.stepper-header {
    box-shadow: none;
}

.buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.button-cancel {
    color: white;
}

.button-skip {
    color: purple;
    background-color: none;
    box-shadow: none;
}

.label-summary {
    width: 100%;
    text-align: center;
}

.fixed-address-container {
    margin: 20px 0 0 0;
}

.fixed-address-latitude {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.fixed-address-longtitude {
    display: flex;
}

.fixed-address__label {
    margin: 13px 0 0 0;
    width: 80px;
}

.map-picker {
    width: 500px;
    height: 250px;
    border-radius: 5px;
}

.subheading-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.postcode-wrapper {
    display: flex;
}

.text-field-small {
    margin: 0 10px 0 0;
}

.buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
</style>
