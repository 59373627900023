<template>
    <div
        id="map"
        class="map"
    />
</template>

<script>
import gmapsInit from "@/plugins/gmaps";

export default {
    name: "mapDiv",

    props: {
        latVal: {
            type: Number,
            required: false,
        },
        lngVal: {
            type: Number,
            required: false,
        },
        displayName: {
            type: String,
            required: false,
        },
    },

    data: () => ({
        editableLat: null,
        editableLng: null,
        mapCenter: null,
        markerPosition: null,
    }),
    methods: {
        placeMarker(location) {
            if (marker == undefined) {
                marker = new google.maps.Marker({
                    position: location,
                    map: map,
                });
            } else {
                marker.setPosition(location);
            }
            map.setCenter(location);
        }
    },

    async mounted() {
        this.editableLat = JSON.parse(JSON.stringify(this.latVal));
        this.editableLng = JSON.parse(JSON.stringify(this.lngVal));

        try {
            const google = await gmapsInit();
            if (this.editableLat && this.editableLng) {
                // this.markerPosition = { lat: parseFloat(this.editableLat), lng: parseFloat(this.editableLng) };
                this.mapCenter = {
                    lat: parseFloat(this.editableLat),
                    lng: parseFloat(this.editableLng),
                };
            } else {
                this.markerPosition = null;
                this.mapCenter = { lat: 53.1444, lng: 2.1111 };
            }

            const map = new google.maps.Map(document.getElementById("map"), {
                center: new google.maps.LatLng(this.mapCenter),
                zoom: 4,
            });

            if (this.markerPosition != null) {
                var marker = new google.maps.Marker({
                    position: this.markerPosition,
                    map,
                });
            }

            map.addListener("click", (event) => {
                const markerPosition = JSON.parse(
                    JSON.stringify(event.latLng.toJSON(), null, 2)
                );

                this.placeMarker(event.latLng);
                this.$emit("latUpdate", markerPosition.lat);
                this.$emit("lngUpdate", markerPosition.lng);
            });
        } catch (error) {
            console.error(error);
        }
    }
}

</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
}

.map {
    width: auto;
    height: 400px;
}
</style>
